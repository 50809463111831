<template>
    <v-card>

        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                Add Details
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-text-field
                            v-if="IsSeatCapacity"
                            v-model="editedData.SeatCapacity"
                            :error-messages="SeatCapacityErrors"
                            label="Seat Capacity"
                            required
                            type="number"
                            @input="$v.editedData.SeatCapacity.$touch()"
                            @blur="$v.editedData.SeatCapacity.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-if="IsDeviceAge"
                            v-model="editedData.DeviceAge"
                            :error-messages="DeviceAgeErrors"
                            label="Device Age"
                            required
                            type="number"
                            @input="$v.editedData.DeviceAge.$touch()"
                            @blur="$v.editedData.DeviceAge.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-if="IsDeviceNumber"
                            v-model="editedData.DeviceNumber"
                            :error-messages="DeviceNumberErrors"
                            label="Device Number"
                            required
                            @input="$v.editedData.DeviceNumber.$touch()"
                            @blur="$v.editedData.DeviceNumber.$touch()"
                    ></v-text-field>
                    <v-text-field v-if="IsMaximumTakeoffweight"
                                  v-model="editedData.MaximumTakeoffweight"
                                  label="Maximum Takeoff Weight"
                                  required
                                  type="number"
                    ></v-text-field>
                    <v-text-field
                            v-if="IsMaximumOperatingAltitude"
                            v-model="editedData.MaximumOperatingAltitude"
                            label="Maximum Operating Altitude"
                            required
                            type="number"
                    ></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success"],
        computed: {
            IsSeatCapacity() {
                return true
            },
            IsDeviceAge() {
                return true;
            },
            IsDeviceNumber() {
                return true;
            },
            IsMaximumTakeoffweight() {
                let check = (this.userData.branchType == "BIKE" || this.userData.branchType == "RAFT" || this.userData.branchType == "PADL") ? false : true
                return check;
            },
            IsMaximumOperatingAltitude() {
                let check = (this.userData.branchType == "BIKE" || this.userData.branchType == "RAFT" || this.userData.branchType == "PADL") ? false : true
                return check;
            },
            SeatCapacityErrors() {
                const errors = [];
                if (!this.$v.editedData.SeatCapacity.$dirty) return errors;
                !this.$v.editedData.SeatCapacity.required &&
                errors.push("Seat capacity is required");
                return errors;
            },
            DeviceAgeErrors() {
                const errors = [];
                if (!this.$v.editedData.DeviceAge.$dirty) return errors;
                !this.$v.editedData.DeviceAge.required &&
                errors.push("Device age is required.");
                return errors;
            },
            DeviceNumberErrors() {
                const errors = [];
                if (!this.$v.editedData.DeviceNumber.$dirty) return errors;
                !this.$v.editedData.DeviceNumber.required &&
                errors.push("Sector number is required.");
                return errors;
            },
        },
        watch: {
            updated: {
                handler: function (val) {
                    if (this.updated) {
                        this.updated = false;
                        this.$emit("formResponse", val);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                province: [],
                show: false,
                updated: false,
                editedData: {
                    SeatCapacity: "",
                    DeviceAge: "",
                    DeviceNumber: "",
                    MaximumTakeoffweight: "",
                    MaximumOperatingAltitude: ""
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                SeatCapacity: {required},
                DeviceAge: {required},
                DeviceNumber: {required}
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                this.updated = false;
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        SeatCapacity: parseInt(this.editedData.SeatCapacity),
                        DeviceNumber: this.editedData.DeviceNumber,
                        DeviceAge: parseInt(this.editedData.DeviceAge),
                        MaximumTakeoffweight: parseInt(this.editedData.MaximumTakeoffweight),
                        MaximumOperatingAltitude: parseInt(this.editedData.MaximumOperatingAltitude)
                    };
                    axios.post("Adventure/InsertIntoAdventureDeviceDetailAsync", param).then(response => {
                        if (response.status == 200) {
                            this.$v.$reset();
                            this.servicesData.data = response.data
                            this.servicesData.message = "Details added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.SeatCapacity = "";
                this.editedData.DeviceAge = "";
            }
        }
    };
</script>

<style scoped>
</style>